<template>
  <div id="header" :class="showMobileMenu && 'position-fixed header-on-show-mobile'">
    <b-navbar :style="'z-index: 10'" class="main-navbar px-md-0" toggleable="lg">
      <b-container class="container-px-lg">
        <div class="d-flex">
          <button class="d-block d-xl-none nav-bar-toggle" @click="onMobileMenuToggle()">
            <div id="burger-icon" :class="showMobileMenu && 'open'">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </button>
          <b-navbar-brand class="ml-1 ml-xl-0 cursor-pointer" @click="$router.push('/')">
            <img alt="" class="main-logo" src="@/assets/images/logo/logo_header.svg">
          </b-navbar-brand>
        </div>

        <b-navbar-nav class="ml-auto d-none d-xl-flex">
          <div v-for="(link, index) in links" :key="index" class="mr-0 mr-xl-1 my-auto dynamic-header-links">
            <router-link v-if="link.to"
                         :class="$route.path === link.to && 'active-link'"
                         :exact="link.exact"
                         :to="link.to"
                         active-class="active-link"
                         style="padding: 1px 6px"
            >
              {{ $t(`lending.nav-items.${link.name}`) }}
            </router-link>
            <button v-else @click="linkHandler(link.id, link.action)">
              {{ $t(`lending.nav-items.${link.name}`) }}
            </button>
          </div>
        </b-navbar-nav>


        <b-navbar-nav class="d-flex flex-row" style="align-items: center;">
          <b-nav-item v-if="!is_auth" class="mx-1 mx-lg-0 my-auto d-none d-md-flex" to="/authorization">
            <p class="const-btn login-btn">{{$t("lending.nav-btns.sign-in")}}</p>
          </b-nav-item>

          <b-nav-item v-if="!is_auth" class="mr-md-1 my-auto" to="/register">
            <p class="const-btn registration-btn">{{$t("lending.nav-btns.registration")}}</p>
          </b-nav-item>
          
          <b-nav-item v-if="is_auth">
            <div style="padding-top: 8px;">
              <a @click="toggleNotification">
                  <span
                    v-if="notifications.count_unread > 0"
                    class="notif-counter"
                    >{{ notifications.count_unread > 9 ? '9+' : notifications.count_unread }}</span
                  >
                <img
                  style="width: 20px "
                  alt="notification"
                  class="notification"
                  src="@/assets/images/icons/notification.svg"
                />
              </a>
            </div>
          </b-nav-item>

          <b-nav-item v-if="is_auth" class="d-none d-xl-block">
            <div class="d-flex flex-column mr-1" style="padding-top: 8px;">
              <b-link
                to="/dashboard"
                class="mb-0 text-black"
                style="font-weight: 500;"
                >{{ user.firstName }} {{ user.lastName }}</b-link
              >
              <b-link @click="logout" class="text-muted" style="font-size: 12px;"
                >{{$t('navigation.log-out')}}</b-link
              >
            </div>
          </b-nav-item>

          <b-nav-item v-if="is_auth" class="d-xl-none">
            <div class="d-flex flex-column mr-1 ml-1" style="padding-top: 8px;">
              <b-link
                to="/dashboard"
                class="mb-0 text-black"
                style="font-weight: 500;"
                >{{ user.lastName }} {{ user.firstName[0] }}.</b-link
              >
              <b-link @click="logout" class="text-muted" style="font-size: 12px;"
                >{{$t('navigation.log-out')}}</b-link
              >
            </div>
          </b-nav-item>


          <b-nav-item-dropdown class="select-language const-btn d-none d-md-flex" style="align-items: center;" right >
            <template #button-content class="const-btn">
              <img alt="" class="select-language-flag my-auto" :src="locale.flag">
              <span class="call-selector">{{locale.abb}}</span>
            </template>
            <b-dropdown-item v-for="(item, index) in locales" :key="index" @click="setLocale(item.code)">
              <img alt="" class="select-language-flag my-auto" :src="item.flag">
              {{item.abb}}
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-container>
    </b-navbar>

    <div :class="showMobileMenu && 'show-mobile-menu'" class="mobile-menu">
      <div v-if="!is_auth">
        <b-nav-item class="mx-1 const-btn mr-lg-0 my-auto d-none mobile-login-btn" to="/authorization"
                    @click="hideMobileMenu">
          {{$t("lending.nav-btns.sign-in")}}
        </b-nav-item>
      </div>
      <div class="reg-btn" v-if="!is_auth">
        <b-nav-item class="mx-1 mt-2 my-auto d-none mobile-registration-btn const-btn" to="/register"
                    @click="hideMobileMenu">
          {{$t("lending.nav-btns.registration")}}
        </b-nav-item>
      </div>

      <div v-for="(link, index) in links" :key="index" >
        <b-nav-item v-if="link.to" :exact="link.exact" :to="link.to" active-class="active-link"
                    class="p-0 mobile-nav-item"
                    @click="hideMobileMenu">
          {{ $t(`lending.nav-items.${link.name}`) }}
        </b-nav-item>
        <div v-else class="mobile-nav-item">
          <button @click="linkHandler(link.id, link.action)">
              {{ $t(`lending.nav-items.${link.name}`) }}
          </button>
        </div>
        <hr v-if="index !== links.length - 1" style="margin: 0">
      </div>

      <b-nav-item-dropdown class="select-language const-btn d-md-flex" style="margin: 40px 14px 6px 14px; text-align: center;" right>
        <template #button-content class="const-btn">
          <img alt="" class="select-language-flag my-auto" :src="locale.flag">
          <span class=" call-selector">{{locale.abb}}</span>
        </template>
        <b-dropdown-item v-for="(item, index) in locales" :key="index" @click="setLocale(item.code)">
          <img alt="" class="select-language-flag my-auto" :src="item.flag">
          {{item.abb}}
        </b-dropdown-item>
      </b-nav-item-dropdown>
     
    </div>

    <showAt breakpoint="mediumAndAbove">
        <notification-view-desktop v-if="showNotif" />
      </showAt>

      <showAt breakpoint="small">
        <notification-view-mobile
          v-if="showNotif"
          @close="closeNotification"
        />
    </showAt>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import { getSupportedLocales } from "@/libs/i18n/modules/supported-locales"
import User from "@/modules/user/"
import NotificationViewDesktop from "@/layouts/components/notification/notification.desktop";
import NotificationViewMobile from "@/layouts/components/notification/notification.mobile";

import notifications from "@/modules/contents/notifications";


export default {
  name: "Header",
  data() {
    return {
      User,
      notifications,
      showNotif: false,
      locales: getSupportedLocales(),
      locale: {
        flag: null,
        abb: null
      },
      links: [
        {to: "/", name: "about", exact: true},
        {id: "earn", action: {type: "change_earn_slide", payload: 0}, name: "hashrate-rental"},
        {id: "earn", action: {type: "change_earn_slide", payload: 1}, name: "miners"},
        {to: "/knowledge", name: "knowledge", exact: false},
        {to: "/news", name: "news", exact: false},
      ],
      showMobileMenu: false,
      is_auth: User.isAuth(),
    }
  },
  methods: {
    ...mapActions(["setEarnOption"]),
    onMobileMenuToggle() {
      this.showMobileMenu = !this.showMobileMenu;

      if (this.showMobileMenu) {
        document.body.style.position = "fixed"
      } else {
        document.body.style.position = ""
      }
    },
    linkHandler(id, action) {
      this.hideMobileMenu()

      if (this.$route.path !== "/") {
        this.$router.push("/#earn")
      } else {
        this.$smoothScroll({
          scrollTo: document.getElementById("earn"),
          duration: 300,
          updateHistory: false
        })
      }

      this.setEarnOption(action.payload)
    },
    hideMobileMenu() {
      this.showMobileMenu = false
      document.body.style.position = ""
    },
    setLocale(locale) {
      this.$i18n.locale = locale
      this.locale = this.locales.find(loc => loc.code === locale);
      User.changeLocale(locale);
    },
    logout() {
        this.$request.post('sessions.finish').then(() => {
            localStorage.removeItem("access_token");
            localStorage.removeItem("current:user");
            sessionStorage.removeItem('access_token');
            this.is_auth = false;
        });
    },
    toggleNotification() {
      this.showNotif = !this.showNotif;

      if (this.showNotif && window.innerWidth <= 530) document.getElementsByTagName('body')[0].style = 'overflow: hidden';

      if (!this.notifications.first_open) {
        this.notifications.first_open = true;
        this.notifications.reading();
      }
    },
    eventToggleNotification(e) {
      if (e.target.className != "notification" && this.showNotif) {
        if (window.innerWidth <= 530) document.getElementsByTagName('body')[0].style = 'overflow: auto';
        this.showNotif = false;
      }
    },
    closeNotification() {
      document.getElementsByTagName('body')[0].style = 'overflow: auto';
      this.showNotif = false;
    },
  },
  mounted() {
    let code = 'ru'

    if( User.isAuth() ) {
      this.notifications.get();
    }

    if (localStorage.getItem('current_locale')) code = localStorage.getItem('current_locale');
    
    this.locale = this.locales.find(loc => loc.code === code);
  },
  created() {
    if (window.innerWidth > 530) window.addEventListener("click", this.eventToggleNotification);
  },
  destroyed() {
    if (window.innerWidth > 530) window.removeEventListener("click", this.eventToggleNotification);
  },
  computed: {
    isLogoLight: () => document.document.hasAttribute("blue_bg"),
    user() {
      return User.self;
    }
  },
  components: {
    NotificationViewDesktop,
    NotificationViewMobile
  }
}
</script>

<style>
.select-language ul.dropdown-menu {
  position: absolute;
}
</style>

<style lang="scss" scoped>
html[bluebg="true"] {
  .navbar {
    background: var(--theme-accent-light) !important
  }

  .nav-bar-toggle {
    filter: invert(1);
  }

  .main-logo {
    filter: brightness(10);
  }

  .dynamic-header-links, .const-btn, .call-selector {
    color: #fff !important;
  }

  .const-btn {
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  .const-btn:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  .mobile-menu {
    background-color: var(--theme-accent-light);

    .mobile-nav-item {
      color: white !important;
    }
  }
}

.main-navbar {
  padding: 24px 20px;
  transition: all .3s ease;
  background: none;
}

html[md="true"] {
  .main-navbar {
    background: white;
  }
}

@media screen and (max-width: 768px) {
  .main-navbar {
    padding: 16px;
  }
}

.nav-bar-toggle {
  outline: none;
  background: none;
  border: none;
  transition-delay: 0.3s;
  margin-top: 10px;

  img {
    height: 24px;
  }
}

img {
  height: 40px;
}

.const-btn {
  display: inline;
  color: var(--theme-accent-light) !important;
  font-size: 14px;

  padding: 14px 22px;
  border: 1px solid rgba(26, 86, 255, 0.18);
  border-radius: 35px;
  transition: .3s;

  a {
    padding: 0;
    color: inherit !important;
  }
}

.const-btn:hover {
  border-color: transparent;
  background-color: rgba(26, 86, 255, 0.1);
}

.select-language {
  template #button-content {
    color: var(--theme-accent-light);
  }

  .select-language-flag {
    margin-right: 5px;
    height: 22px;
  }

  margin-top: -1px;
  margin-left: -5px;
  display: block;
  padding: 6px 18px;
}

.mobile-nav-item {
  .active-link {
    color: var(--theme-accent-light) !important;
  }
}

.dynamic-header-links {
  font-size: 14px;
  color: var(--text-primary-color) !important;
  font-weight: 500;
  margin-right: 1vw !important;

  .active-link {
    color: var(--theme-accent-light) !important;
  }

  a {
    color: inherit !important;
    text-align: center;
    transition: all .3s ease;
  }

  button {
    color: inherit !important;
    transition: all .3s ease;
    background: none;
    outline: none;
    border: none;
    font-weight: inherit !important;
  }
}


.mobile-menu {
  height: 0;
  overflow: hidden;
  transition: .3s;
  list-style-type: none;
  position: fixed;
  background-color: white;
  z-index: 2;
  width: 100%;
}

.header-on-show-mobile {
  position: fixed;
  width: 100%;
  z-index: 10;
  top: 0;
  background-color: white;

  .navbar {
    background: white;
  }
}

.show-mobile-menu {
  height: calc(100vh - 80px) !important;
  padding-top: 20px;
}

.mobile-nav-item {
  font-weight: 500;
  font-size: 15px;
  color: var(--text-primary-color);

  button, a {
    padding: 15px 32px;
    color: inherit !important;
    background: none;
    outline: none;
    border: none;
    font-size: inherit !important;
    font-weight: inherit !important;
  }
}

@media screen and (max-width: 768px) {
  .mobile-login-btn {
    display: block !important;
    margin-bottom: 10px;
    text-align: center;
  }
}

@media screen and (max-width: 530px) {
  img {
    height: 32px;
  }
}

@media screen and (max-width: 405px) {
  .registration-btn {
    display: none;
  }

  .mobile-registration-btn {
    display: block !important;
    text-align: center;
  }

  .reg-btn {
    margin-top: 6px;
  }
}

#burger-icon {
  width: 24px;
  height: 24px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#burger-icon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #1A1C23;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#burger-icon span:nth-child(1) {
  top: 0px;
}

#burger-icon span:nth-child(2), #burger-icon span:nth-child(3) {
  top: 9px;
}

#burger-icon span:nth-child(4) {
  top: 18px;
}

#burger-icon.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#burger-icon.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#burger-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#burger-icon.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.notif {
  z-index: 11;
  top: 90px;
  right: 100px;
}

.notif-counter {
  padding: 1px 6px;
  padding-top: 2px;
}

.notif-mobile-wrapper {
  z-index: 11;
}

.navbar .container {
  flex-wrap: nowrap;
}

</style>
