<template>
    <div>
            <showAt breakpoint="large">

                <large-view :item="item" :compact='compact' :count="count">
                    <template slot="action">
                        <slot name="action"></slot>
                    </template>
                    <template slot="guarantee">
                        <slot name="guarantee"></slot>
                    </template>
                </large-view>

            </showAt>
            <showAt breakpoint="medium">
                <medium-view :item="item" :compact='compact' :count="count">
                    <template slot="action">
                        <slot name="action"></slot>
                    </template>
                    <template slot="guarantee">
                        <slot name="guarantee"></slot>
                    </template>
                </medium-view>
            </showAt>
            <showAt breakpoint="small">
                <small-view :item="item" :compact='compact' :count="count">
                    <template slot="action">
                        <slot name="action"></slot>
                    </template>
                    <template slot="guarantee">
                        <slot name="guarantee"></slot>
                    </template>
                </small-view>
            </showAt>
        
        <div class="bg-gray" style="height: 1px;"></div>
    </div>
</template>

<script>

    import SmallView from "./small"
    import MediumView from "./medium"
    import LargeView from "./large"

    export default {

        data() {
            return {}
        },
        props: {
            item: Object,
            count: Number,
            compact: {
                type: Boolean,
                default: false
            }
        },
        methods: {

        },
        components: {
            SmallView,
            MediumView,
            LargeView
        },
        watch: {

        },
        mounted() {

        }

    }

</script>

<style>

    .miner-name {
        font-family: Suisse Intl;
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 22px;
    }
    
    .miner-price {
        font-weight: 500;
        font-size: 22px;
        line-height: 24px;
    }
    
    .miner-info {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
    }
    
    .miner-info li {
        margin-bottom: 4px;
    }
    
</style>