<template>
  <b-card-body class="notif-item" style="padding: 0px 0px 12px 0px; margin-bottom: 12px">

        <b-card-text class="text-muted" style="font-size: 12px; margin: 0px">
          {{item.date}} {{item.time}}
        </b-card-text>

        <b-card-title class="d-flex" style="font-weight: 600; font-size: 14px; line-height: 20px; margin-bottom: 16px; justify-content: space-between; align-items: center;">
            {{ $i18n.locale === 'ru' ? item.title : item.title_en || item.title}}
            <div v-if="!item.reading" class="notif-reading"></div>
         </b-card-title>

        <b-card-text v-if="item.text && item.text.length > 0" class="text-muted" style="font-size: 14px; line-height: 20px;">
          {{$i18n.locale === 'ru' ? item.text : item.text_en || item.text}}
        </b-card-text>

        <b-button v-if="item.type == 2" :to="{ name: 'user-referral' }" variant="primary" style="width: 100%; font-weight: 400; padding: 12px 20px; line-height: 14px; font-size: 14px; border-radius: 12px;">{{$t('notification-learn-more')}}</b-button>

  </b-card-body>
</template>

<script>
  export default {
    data() {
      return {
      }
    },
    props: {
      item: {type: Object}
    },
    mounted() {
    }
  }
</script>

<style lang="scss">
  .notif-item {
    border-bottom: 1px solid #EFEFEF;
  }

  .notif {
    .notif-item:last-child {
      border-bottom: none;
      padding-bottom: 0px !important;
      margin-bottom: 0px !important;
    }
  }

  .notif-mobile {
     .notif-item:last-child {
        border-bottom: none;
        padding-bottom: 0px !important;
        margin-bottom: 65px !important;
     }
  }

  .notif-reading {
    min-width: 12px;
    width: 12px;
    height: 12px;
    background: #FF6F71;
    border-radius: 50px;
  }
</style>