<template>
    <div class="d-flex flex-column">
        <div class="d-flex justify-content-between" :style="compact ? '' : 'padding: 16px 0'">
            
            <div :style="mediaViewStyle()"></div>
            
            <div class="d-flex flex-column justify-content-center" style="width: 100%; padding: 0px 20px;">
                <p class="miner-name m-0">{{ item.name }}</p>
                <div class="d-flex" style="margin-top: 6px;">
                    <div style="margin-right: 16px;">{{ item.hashrate }} <span class="text-muted">TH/s</span></div>
                    <div style="margin-right: 16px;">{{ item.energy_usage }} <span class="text-muted">W</span></div>
                    <div style="margin-right: 16px;">{{ item.energy_usage }} <span class="text-muted">J/TH</span></div>
                </div>
            </div>
            
            <div style="align-self: center; padding: 0 22px;">
            <slot name="action"></slot>
            </div>
            
            <div v-if='compact' style='min-width: 175px; text-align: right; align-self: center;'>
                <p class="miner-price m-0">{{ (item.price_amount * count).formatMoney(0, "", " ") }} <span class="text-muted">$</span></p>
            </div>
            
            <div v-else style="align-self: center; min-width: 175px; text-align: left; padding: 0 22px;">
                <p class="miner-price m-0">{{ (item.price_amount * count).formatMoney(0, "", " ") }} <span class="text-muted">$</span></p>
            </div>
            
            <div v-if='compact === false' style="align-self: center; min-width: 42px; text-align: center;">
                <a @click="preferDeleteAction" class="text-muted">
                    <feather-icon icon="TrashIcon" />
                </a>
            </div>
            
        </div>

        <slot name="guarantee"></slot>
        
        <hr class="mt-0 mb-0">
    </div>


    
</template>

<script>
    
    import Contracts from "@/modules/user/contracts"
    import User from "@/modules/user/"
    import Utils from "@/modules/utils/"
    import miners from "@/modules/contents/miners"

    export default {

        data() {
            return {
                miners
            }
        },
        props: {
            item: Object,
            count: {
                type: Number,
                default: 1,
            },
            compact: Boolean
        },
        methods: {
            
            preferDeleteAction( args ) {
                this.$delete( this.miners.cart, this.item.id );
            },
            
            mediaViewStyle() {
                return {
                    height: '90px',
                    width: '90px',
                    minWidth: '90px',
                    backgroundImage: `url(${this.media.urls.thumb})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundColor: "white",
                    borderRadius: "12px"
                }
            },
        },
        components: {

        },
        watch: {

        },
        
        computed: {
            
            payback() {
                let days = Math.round( parseInt(this.item.price_amount) / parseInt(this.daily_currency) );      
                return days + ' ' + Utils.declOfNum( days, [ 'день', 'дня', 'дней' ]);
            },
            
            daily_currency() {
               return (this.daily_profit * this.$blockchain.tickers.values["USD"].last).formatMoney(2, "", " ");  
            },
            
            daily_profit() {
                 return ( ((84600 * Contracts.values.R * ( Contracts.values.H * this.item.hashrate )) / ( Contracts.values.D * Math.pow(2, 32) )) ).toFixed(8);
            },
            
            media() {
                return this.item.medias[0];
            }
            
        },
        mounted() {

        }

    }

</script>