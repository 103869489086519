<template>
  <section class="black-section">
    <b-container class="py-3 container-px-lg">
      <b-row>
        <b-col class="mb-2 mb-lg-0 px-lg-0 px-0" cols="12" md="4" xl="2">
          <!--logo + contacts-->
          <b-col cols="6" md="8">
            <img alt="" class="w-100" src="@/assets/images/site/icons/Logo.svg">
          </b-col>
          <b-col class="mt-2 d-flex d-md-block">
            <q-header :centered="false" class="mr-2" color="#fff" level="6" size=""><a style="color: #fff" href="tel:+78007758598">+7 (800) 775-85-98</a></q-header>
            <q-header :centered="false" color="#fff" level="6" size=""><a style="color: #fff" href="mailto:info@qubity.io">info@qubity.io</a></q-header>
          </b-col>
        </b-col>
        <b-col class="mb-1 mb-md-3 px-lg-0 px-0" cols="12" md="4" xl="2">
          <FooterLinkGroup @handle="linkHandler" :company-links="company">
            <template>
              {{ $t("lending.nav-groups.company")}}
            </template>
          </FooterLinkGroup>
        </b-col>
        <b-col class="mb-1 mb-md-3 px-lg-0 px-0" cols="12" md="4" xl="2">
          <FooterLinkGroup @handle="linkHandler" :company-links="mining">
            <template>
              {{ $t("lending.nav-groups.mining")}}
            </template>
          </FooterLinkGroup>
        </b-col>
        <b-col class="mb-1 mb-md-3 px-lg-0 px-0" cols="12" md="4" xl="2">
          <FooterLinkGroup @handle="linkHandler" :company-links="info">
            <template>
              {{ $t("lending.nav-groups.info")}}
            </template>
          </FooterLinkGroup>
        </b-col>
        <b-col class="mb-1 mb-md-3 px-lg-0 px-0" cols="12" md="4" xl="2">
          <FooterLinkGroup @handle="linkHandler" :company-links="contacts">
            <template>
              {{ $t("lending.nav-groups.contacts")}}
            </template>
          </FooterLinkGroup>
        </b-col>
        <b-col class="d-flex p-relative" cols="12" md="4" xl="2">
          <div v-if="utils.listing.items.length > 0" class="bitcoin mt-0 p-1 mb-auto w-100 justify-content-between overflow-hidden">
            <div class="w-100">
              <q-header :centered="false" color="#fff" level="5" size="">{{ utils.listing.items[0].symbol.toUpperCase() }}<span
                  class="btc-transcript">({{ utils.listing.items[0].name }})</span></q-header>
              <p class="mb-0 d-block text-nowrap w-100"> {{utils.listing.items[0].market_price_usd.formatMoney(2," "," ")}} $ 
                <span v-if="utils.listing.items[0].market_price_percent_change_24h > 0" class="btc-increase">+{{ utils.listing.items[0].market_price_percent_change_24h.toFixed(2) }}%</span>
                <span v-else style="font-size: 16px" class="text-danger">{{ utils.listing.items[0].market_price_percent_change_24h.toFixed(2) }}%</span>
              </p>
            </div>
            <img alt="" class="bitcoin-course position-absolute" src="@/assets/images/site/icons/btc.svg">
          </div>
        </b-col>
      </b-row>
      <b-row class="my-1">
        <b-col cols="12">
          <hr class="w-100">
        </b-col>
      </b-row>
      <b-row class="mt-md-3 flex-column-reverse flex-md-row justify-content-between">
        <b-col class="description d-flex flex-column flex-xl-row justify-content-between" cols="12" md="6" xl="8">
          <p>© 2022 «Qubity Inc OÜ»</p>
          <div class="d-flex flex-column flex-xl-row">
            <router-link class="description-link mr-3" :to="$i18n.locale === 'ru' ? '/policy' : '/policy-en'">{{ $t("docs.privacy-policy")}}</router-link>
            <router-link class="description-link" to="/public-offer">{{ $t("docs.public-offer")}}</router-link>
          </div>
        </b-col>
        <b-col class="description d-flex justify-content-start justify-content-md-end mb-1" cols="12" md="6" xl="3">
          <a href="https://www.instagram.com/qubity.io/" target="_blank">
            <img alt="" class="network_icon" src="@/assets/images/site/icons/networks_icons/Group 5.svg">
          </a>
          <!-- <router-link to>
            <img alt="" class="network_icon" src="@/assets/images/site/icons/networks_icons/Group 2.svg">
          </router-link>
          <router-link to>
            <img alt="" class="network_icon" src="@/assets/images/site/icons/networks_icons/Group 6.svg">
          </router-link> -->
          <a href="https://t.me/qubity_store" target="_blank">
            <img alt="" class="network_icon" src="@/assets/images/site/icons/networks_icons/telegram.svg">
          </a>
          <a href="https://zen.yandex.ru/qubity" target="_blank">
            <img alt="" class="network_icon" style="padding: 2px; margin-right: 6px" src="@/assets/images/site/icons/networks_icons/zen-icon.png">
          </a>
          <!-- <router-link to>
            <img alt="" class="network_icon" src="@/assets/images/site/icons/networks_icons/Group 3.svg">
          </router-link>
          <router-link to>
            <img alt="" class="network_icon" src="@/assets/images/site/icons/networks_icons/Group 4.svg">
          </router-link> -->
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import FooterLinkGroup from "@/views/Main/main-design-v2/components/components/FooterLinkGroup";
import {mapActions} from "vuex";
import utils from "@/modules/utils"

export default {
  name: "Footer",
  components: {FooterLinkGroup},
  data() {
    return {
      utils,
      // todo сделать ссылки на id
      company: [
        {link: "/", name: "lending.nav-items.about"},
        {id: "reviews", name: "lending.nav-items.reviews"},
        {link: "/news", name: "lending.nav-items.blog"}
      ],
      mining: [
        {id: "earn", action: {type: "change_earn_slide", payload: 0}, name: "lending.nav-items.hashrate-rental"},
        {id: "earn", action: {type: "change_earn_slide", payload: 1}, name: "lending.nav-items.buy-miner"},
        {id: "offers", name: "lending.nav-items.special-offers"}
      ],
      info: [
        {link: "/knowledge", name: "lending.nav-items.knowledge"},
        {link: "/vacancies", name: "lending.nav-items.jobs"},
        {id: "faq", name: "lending.nav-items.faq"}
      ],
      contacts: [
        {link: "/", name: "lending.nav-items.support"},
        {modal: "consultation", name: "lending.nav-items.write-to-us"}
      ],
    }
  },
  methods: {
    ...mapActions(["setEarnOption"]),
    linkHandler(id, action={}) {
      if (this.$route.path !== "/") {
        this.$router.push("/#" + id)
      } else {
        this.$smoothScroll({
          scrollTo: document.getElementById(id),
          duration: 300,
          updateHistory: false
        })
      }

      if (action.type) {
        this.setEarnOption(action.payload)
      }
    },
  },
  mounted() {
    if( utils.listing.items.length === 0 ) {
      utils.listing.get();
    }
  }
}
</script>

<style lang="scss" scoped>
.black-section {
  p {
    color: #fff;
    line-height: 1;
  }

  hr {
    border-top: #313131 1px solid;
  }

  background-color: var(--text-primary-color);
}

.bitcoin {
  .btc-transcript {
    color: #AAAAAA;
  }

  .btc-increase {
    color: #47BCAA;
    font-size: 16px;
  }

  .bitcoin-course {
    right: 16px;
    top: 1px;
  }

  background-color: #313131;
  border-radius: 20px;
  overflow: hidden;
}

.description {
  p, .description-link {
    font-weight: lighter;
    color: #fff;
    margin: 0;
    line-height: 1.6;
  }
}

.network_icon {
  width: 35px;
  height: 35px;
  margin-right: 15px;
}
</style>
