<template>
  <b-col>
    <div class="faq-itemd-block d-md-none">
      <div class="faq-question d-flex justify-content-between" @click="toggleFooterLinkGroupList">
        <span class="my-auto">
          <slot>
          </slot>
        </span>
        <span class="icon d-block d-md-none my-auto">
          <img :class="!isOpen && 'icon-faq-plus'" alt="" class="icon-symbol" src="@/assets/images/site/icons/plus_in_circle.svg">
        </span>
      </div>
      <b-collapse id="faq-item" :visible="isOpen">
        <div class="d-flex flex-column">
          <div style="display: contents" v-for="(companyLink, index) in companyLinks" :key="index">
            <div class="footer-link" @click="$emit('handle', companyLink.id, companyLink.action || {})" v-if="companyLink.id">
              {{$t(companyLink.name)}}
            </div>
            <router-link v-else-if="companyLink.link" :to="companyLink.link" class="footer-link">
              {{$t(companyLink.name)}}
            </router-link>
            <div v-else class="footer-link" v-b-modal:consultation>
              {{$t(companyLink.name)}}
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
    <div class="flex-column d-md-flex d-none">
      <div class="faq-question mb-1">
        <span class="my-auto">
          <slot>
          </slot>
        </span>
      </div>
      <div style="display: contents" v-for="(companyLink, index) in companyLinks" :key="index">
        <div class="footer-link" @click="$emit('handle', companyLink.id, companyLink.action || {} )" v-if="companyLink.id">
          {{$t(companyLink.name)}}
        </div>
        <router-link v-else-if="companyLink.link" :to="companyLink.link" class="footer-link">
          {{$t(companyLink.name)}}
        </router-link>
        <div v-else class="footer-link" v-b-modal:consultation>
          {{$t(companyLink.name)}}
        </div>
      </div>
    </div>
  </b-col>
</template>

<script>
export default {
  name: "FooterLinkGroup",
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    toggleFooterLinkGroupList() {
      this.isOpen = !this.isOpen
    }
  },
  props: {
    companyLinks: {type: Array}
  }
}
</script>

<style lang="scss" scoped>
span, .faq-answer, .footer-link {
  color: #fff;
}

.footer-link {
  cursor: pointer;
  font-size: 16px;
  line-height: 1.3;
  margin-bottom: 8px;
}

.faq-question {
  font-size: 24px;
  margin-bottom: 5px;

  span {
    cursor: pointer;
  }

  .icon {
    transition: all .3s ease;
    border: solid 1px var(--text-primary-color);
    border-radius: 100px;
    aspect-ratio: 1;
    width: 28px;
    height: 28px;
    display: flex;
    padding: 4px;

    .icon-symbol {
      transition: all .3s ease;
      width: 100%;
    }
  }
}

.icon-faq-plus {
  transform: rotate(45deg);
}
</style>
