<template>
    
    <div class="d-flex flex-column justify-content-between">
        
        <div :style="mediaViewStyle()"></div>
        
        <div class="d-flex flex-column justify-content-start" style="width: 100%; padding: 0px;">
            <p class="miner-name">{{ item.name }}</p>
            <div class="d-flex miner-info">
                <div style="padding-right: 16px; border-right-width: 1px;" class="with-seperator">
                    <ul class="list-unstyled">
                        <li><span class="text-muted">{{$t('catalog.hashrate')}}:</span> {{ item.hashrate }} <span class="text-muted">TH/s</span> </li>
                        <li><span class="text-muted">{{$t('catalog.energy-consumption')}}:</span> {{ item.energy_usage }} W </li>
                        <li><span class="text-muted">{{$t('catalog.currency')}}:</span> BTC </li>
                        <li><span class="text-muted">{{$t('catalog.income-per-day')}}:</span> {{ daily_profit }} BTC ≈ {{ daily_currency }} USD</li>
                        <!-- <li><span class="text-muted">{{$t('catalog.rate-of-return')}}:</span> {{ payback }} </li> -->
                        <li><span class="text-muted">{{$t('catalog.start-mining')}}:</span> {{$t('catalog.in')}} 30 {{$t('catalog.days')}} </li>
                    </ul>
                </div>
            </div>
        </div>
        
        <div class="d-flex flex-column justify-content-start" style="min-width: 175px;">
            <p class="miner-price">{{ item.price_amount.formatMoney(0, "", " ") }} <span class="text-muted">USD</span></p>
            <slot name="action"></slot>
        </div>

        <slot name="guarantee"></slot>
        
    </div>
    
</template>

<script>
    
    import Contracts from "@/modules/user/contracts"
    import User from "@/modules/user/"
    import Utils from "@/modules/utils/"

    export default {

        data() {
            return {}
        },
        props: {
            item: Object
        },
        methods: {
            mediaViewStyle() {
                return {
                    height: `255px`,
                    width: '100%',
                    minWidth: '150px',
                    backgroundImage: `url(${this.media.urls.thumb})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center"
                }
            },
        },
        components: {

        },
        watch: {

        },
        
        computed: {
            
            payback() {
                let days = Math.round( parseInt(this.item.price_amount) / parseInt(this.daily_currency) );      
                return days + ' ' + Utils.declOfNum( days, [ 'день', 'дня', 'дней' ]);
            },
            
            daily_currency() {
               return (this.daily_profit * this.$blockchain.tickers.values["USD"].last).formatMoney(2, "", " ");  
            },
            
            daily_profit() {
                 return ( ((84600 * Contracts.values.R * ( Contracts.values.H * this.item.hashrate )) / ( Contracts.values.D * Math.pow(2, 32) )) ).toFixed(8);
            },
            
            media() {
                return this.item.medias[0];
            }
            
        },
        mounted() {

        }

    }

</script>