<template>
    <div class="data-form">

        <center>
            <div class="modal-title">
                <b-img
                src="~@/assets/images/site/guarantee-miner.png"
                fluid
                alt=""
                style="border-radius: 16px;"
            ></b-img>
            </div>

            <div style="padding: 20px 24px 4px 24px">
                 <p style="font-size: 22px; font-weight: 500; line-height: 24px; text-align:start">
                    {{$t('modals.guarantee.equipment.header')}} <br> 
                    <span class="text-muted">{{$t('modals.guarantee.equipment.header-before')}}</span>
                </p>
                <p style="font-size: 14px; line-height: 20px;">
                    <p style="text-align:start">{{$t('modals.guarantee.equipment.p-1')}}</p>
                    <p style="text-align:start">{{$t('modals.guarantee.equipment.p-2')}}<br>{{$t('modals.guarantee.equipment.p-2-before')}}</p>
                </p>
            </div>

        
        </center>
      
    </div>
</template>

<script>
export default {
    data() {
        return {
            is_loading: false,
        }
    },
    mounted() {
    }
}
</script>
