<template>
    
    <div class="d-flex justify-content-between">
        
        <div :style="mediaViewStyle()"></div>
        
        <div class="d-flex flex-column justify-content-start flex-grow-1" style=" padding: 0px 32px;">
            
            <p class="miner-name m-0 mt-auto">{{ item.name }}</p>
            
             <div class="d-flex mb-auto" style='margin-top: 6px;'>
                <div style="margin-right: 16px; font-size: 14px;">{{ item.hashrate }} <span class="text-muted">TH/s</span></div>
                <div style="margin-right: 16px; font-size: 14px;">{{ item.energy_usage }} <span class="text-muted">W</span></div>
                <div style="margin-right: 16px; font-size: 14px;">{{ item.energy_performance }} <span class="text-muted">J/TH</span></div>
            </div>

        </div>
        
        <slot name="action"></slot>
        
        <div class="d-flex align-self-center ml-2">
            <p class="miner-price m-0">{{ item.price_amount.formatMoney(0, "", " ") }} <span class="text-muted">$</span></p>
        </div>

        <slot name="guarantee"></slot>
        
    </div>
    
</template>

<script>
    
    import Contracts from "@/modules/user/contracts"
    import User from "@/modules/user/"
    import Utils from "@/modules/utils/"

    export default {

        data() {
            return {}
        },
        props: {
            item: Object
        },
        methods: {
            mediaViewStyle() {
                return {
                    height: '85px',
                    width: '85px',
                    minWidth: '85px',
                    backgroundImage: `url(${this.media.urls.thumb})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center"
                }
            },
        },
        components: {

        },
        watch: {

        },
        
        computed: {
            
            payback() {
                let days = Math.round( parseInt(this.item.price_amount) / parseInt(this.daily_currency) );      
                return days + ' ' + Utils.declOfNum( days, [ 'день', 'дня', 'дней' ]);
            },
            
            daily_currency() {
               return (this.daily_profit * this.$blockchain.tickers.values["USD"].last).formatMoney(2, "", " ");  
            },
            
            daily_profit() {
                 return ( ((84600 * Contracts.values.R * ( Contracts.values.H * this.item.hashrate )) / ( Contracts.values.D * Math.pow(2, 32) )) ).toFixed(8);
            },
            
            media() {
                return this.item.medias[0];
            }
            
        },
        mounted() {

        }

    }

</script>