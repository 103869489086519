<template>
    <transition duration="550" name="nested">
        <div class="d-flex notif outer" style="padding: 24px 0px;"> 
            <div class="card-body">
                <div class="inner" style="padding: 0px">
                    <b-card-title class="mb-1" style="font-size: 20px"> {{$t('notifications')}} </b-card-title>
                    
                    <item-view v-for="(item, index) in notifications.dataSource.items" :key="index" :item="item" />

                </div>
            </div>
        
        </div>
    </transition>
</template>

<script>
import ItemView from "./item.notification"
import notifications from "@/modules/contents/notifications"

export default {
    data() {
        return {
            notifications
        }
    },
    components: {
        ItemView
    },
}
</script>

<style lang="scss">

    .notif {
        .card-body:nth-child(1) {
            padding: 0px 24px !important;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

//     .outer, .inner {
//     min-height: 340px;
//   }
    
  .nested-enter-active, .nested-leave-active {
    transition: all 0.3s ease-in-out;
  }
  /* delay leave of parent element */
  .nested-leave-active {
    transition-delay: 0.25s;
  }

  .nested-enter-from,
  .nested-leave-to {
    transform: translateY(30px);
    opacity: 0;
  }

  /* we can also transition nested elements using nested selectors */
  .nested-enter-active .inner,
  .nested-leave-active .inner { 
    transition: all 0.3s ease-in-out;
  }
  /* delay enter of nested element */
  .nested-enter-active .inner {
    transition-delay: 0.25s;
  }

  .nested-enter-from .inner,
  .nested-leave-to .inner {
    transform: translateX(30px);
    /*
      Hack around a Chrome 96 bug in handling nested opacity transitions.
      This is not needed in other browsers or Chrome 99+ where the bug
      has been fixed.
    */
    opacity: 0.001;
  }

</style>